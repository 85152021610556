<template>
  <div class="wrapper-popup">
    <div class="overlay" />
    <div class="container-popup" style="color:#4D4D4D;line-height:18px;font-size: 14.5px;">
      <div style="padding-left: 15%;padding-right: 15%">{{ $t('home.afterSurvey.text') }}</div>
      <div class="flex justify-center mt-4">
        <button
          @click="toggleMenu"
          class="bg-jublia-blue text-white text-base font-bold rounded-lg px-4 py-0 mr-4"
        >
          {{ $t('home.afterSurvey.text1') }}
        </button>
        <button  
          @click="goToAboutPage"
          class="bg-jublia-blue text-white text-base font-bold rounded-lg px-4 py-0 ml-4"
        >
          {{ $t('home.afterSurvey.text2') }} {{v}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
 
  methods: {
    goToAboutPage() {
      this.$router.push('/about'); //todo: use about page
    },
    ...mapMutations('menu',['toggleMenu'])
  }
}
</script>

<style scoped>
.wrapper-popup {
  @apply fixed top-0 left-0 w-full h-full flex items-end px-4 pb-10;
  overflow-y: hidden;
}

.container-popup {
  @apply w-full bg-white rounded-lg border-jublia-purple text-center p-6 z-10;
  max-width: 900px;
  margin: auto;
}

.overlay {
  @apply fixed top-0 left-0 w-full h-screen bg-jublia-bluest opacity-75;
}

  button{
    width:208px;
    height: 38px;
    font-size: 1rem;
    line-height: 13px;
  }
</style>
